import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Button from '../components/shortcodes/Button'

const NotFoundPage = () => (
  <Layout
    className="is-page-404 has-text-centered hide-contact-form"
    seoTitle="404 Not Found"
  >
    <section className="section">
      <Hero title="404 Not Found" />
      <div className="container content is-narrow">
        <p>
          It looks like you took a wrong turn. We couldn't find the page you
          were looking for.
        </p>
        <Button url="/" text="Go Back" />
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
